import React from 'react'
import { Helmet } from 'react-helmet'
import Header from './header'
import {graphql, useStaticQuery} from 'gatsby'

const Head = ({title}) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    return (
        <Helmet title={`${title} | ${data.site.siteMetadata.title}`}>
            <script data-ad-client="ca-pub-1435869001586096" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        </Helmet>
    )
}

export default Head;