import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import footerStyles from "../styles/modules/footer.module.scss"

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site_data: site {
              siteMetadata {
                title
                url
              }
            }
            footer_bg: file(relativePath: { eq: "assets/img/footer-1.jpg" }) {
                childImageSharp {
                    fluid {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                    }
                }
            }

            payment_img: file(relativePath: { eq: "assets/img/payment.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                    }
                }
            }
        }
    `)

    console.log(data)

    return (
        <footer
            className={footerStyles.footer}
            style={{
                backgroundImage: `url(${data.footer_bg.childImageSharp.fluid.src})`,
            }}
        >
            <div className="container">
                <div className="row">
                    <div className={footerStyles.footerLinks}>
                        <div className="links">
                            <h6>About Us</h6>
                            <p>Netrumo.com is an international online business magazine inspiring enterprenuers around the world making the world a better place.</p>
                        </div>
                        <div className="links">
                            <h6>Useful Links</h6>
                            <ul>
                                <li><a href="/terms-of-use/">Terms and Conditions</a></li>
                                <li><a href="/blog/">Blog</a></li>
                                <li><a href="https://www.facebook.com/netrumo/">Facebook</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={footerStyles.footerBottom}>
                <div className="container">
                    <div className="row">
                        <div className="copyright">
                        <p>© COPYRIGHT 2021 <a href={data.site_data.siteMetadata.url}>{data.site_data.siteMetadata.title}</a> ALL RIGHTS RESERVED.</p>
                        </div>
                        <div className="social"></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
