import React from 'react';
import Header from './header';
import Footer from './footer';

import '../styles/index.scss';

const Layout = (props)  => {
    return (
        <div className={props.css_class}>
            <Header />
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout